<template>
  <div>
    <header-slot>
      <template #actions>
        <header-buttons
          :leadId="getLeadId"
          :has-others-programs="hasPrograms"
          @openShareModal="openModalShare"
          @openModalProgramList="openModalProgramList"
          @openModalSendSms="openModalSendSms"
          @openModalHistorySms="openModalHistorySms"
          @openModalSendEmail="openModalSendEmail"
          @openModalHistoryEmail="openModalHistoryEmail"
          @openModalHistoryClaims="openModalTrackingHistoryClaims"
        />
      </template>
    </header-slot>

    <tabs v-if="!isBusy" />
    <modal-share
      v-if="activeModalShare"
      :modal-share="activeModalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="activeModalProgramList"
      :modal-program-list="activeModalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <modal-send-sms
      v-if="activeModalSendSms"
      :modal-send-sms="activeModalSendSms"
      :name-leads="{name:client, id: accountId}"
      :typesms="0"
      @closeModal="closeModalSendSms"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-send-email
      v-if="activeModalSendEmail"
      :modal-send-email="activeModalSendEmail"
      :name-leads="[{name:client, id: accountId}]"
      :typesms="0"
      @closeModal="closeModalSendEmail"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      :n-client-account-id="accountId"
      :account-client="account"
      :module="moduleId"
      @close="closeModalTrackingHistoryClaims"
    />
  </div>
</template>
<script>
//components
import HeaderSlot from "./components/utils/HeaderSlot.vue";
import HeaderButtons from "./components/utils/HeaderButtons.vue";
import Tabs from "./components/sections/Tabs.vue";
//modals
import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare.vue";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalHistorySms from "./components/modals/ModalHistorySms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalHistoryEmail from "./components/modals/ModalHistoryEmail.vue";
import ModalTrackingHistoryClaims from "@/views/commons/components/claims_v2/components/Modals/ModalTrackingHistoryClaims";

//vuex
import { mapActions, mapGetters } from "vuex";
import store from '@/store';

export default {
  components: {
    HeaderSlot,
    HeaderButtons,
    Tabs,
    ModalShare,
    ModalProgramList,
    ModalSendSms,
    ModalHistorySms,
    ModalSendEmail,
    ModalHistoryEmail,
    ModalTrackingHistoryClaims,
  },
  data() {
    return {
      isBusy: true,
      activeModalShare: false,
      activeModalProgramList: false,
      activeModalSendSms: false,
      activeModalHistorySms: false,
      activeModalSendEmail: false,
      activeModalHistoryEmail: false,
      modalHistoryClaims: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    clientId() {
      return this.$route.params.idClient;
    },
    hasPrograms() {
      return !!this.counterPrograms;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      getLeadId: 'ParagonClientDashboard/getLeadId',
      counterPrograms: 'ParagonClientDashboard/getCounterPrograms',
      client: 'ParagonClientDashboard/getClient',
      accountId: 'ParagonClientDashboard/getAccountId',
      account: 'ParagonClientDashboard/getAccount',
    }),
  },
  async created() {
    this.isResponsible();
    this.isBusy = true;
    await this.start();
    this.isBusy = false;
  },
  mounted() {
    if (this.$route.matched[0].meta.general) {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
      this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'sticky' });
    }
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', {
      type: 'sticky',
    });
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical');
  },
  methods: {
    isResponsible() {
      this.$store.dispatch('NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO', { account_id: this.$route.params.idClient });
    },
    async start() {
      this.addPreloader();
      this.setMonths();
      this.setYears();
      await Promise.all([
        this.getClientInformation(this.clientId),
        this.getCompanyInformation(this.clientId),
        this.getTaskInformation({
          id: this.clientId,
          user_id: this.currentUser.user_id,
        }),
        this.getNotesData({
          id: this.clientId,
          year: new Date().getFullYear(),
          user_id: this.currentUser.user_id,
        }),
        this.getCharges(this.clientId),
        this.getPayments(this.clientId),
        this.getClientToFiles({ id: this.clientId }),
      ]);
      await this.getScores(this.getLeadId);
      this.removePreloader();
    },
    async openModalSendEmail() {
      this.activeModalSendEmail = true;
    },
    closeModalSendEmail() {
      this.activeModalSendEmail = false;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.accountId, type: 0 });
      this.activeModalHistorySms = true;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    async openModalSendSms() {
      await this.getHistorySms({ id: this.accountId, type: 0 });
      this.activeModalSendSms = true;
    },
    closeModalSendSms() {
      this.activeModalSendSms = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.clientId);
      this.activeModalHistoryEmail = true;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    openModalProgramList() {
      this.activeModalProgramList = true;
    },
    closeModalProgramList() {
      this.activeModalProgramList = false;
    },
    openModalShare() {
      this.activeModalShare = true;
    },
    closeModalShare() {
      this.activeModalShare = false;
    },

    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },
    ...mapActions({
      getClientInformation: "ParagonClientDashboard/getClientData",
      getCompanyInformation: "ParagonClientDashboard/getCompanyData",
      getTaskInformation: "ParagonClientDashboard/getTaskData",
      getNotesData: "ParagonClientDashboard/getNotesData",
      getCharges: "ParagonClientDashboard/getCharges",
      getPayments: "ParagonClientDashboard/getPayments",
      getScores: "ParagonClientDashboard/getScores",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      getClientToFiles: "DebtSolutionClients/A_GET_CLIENTS",

      setMonths: 'ParagonClientDashboard/setMonths',
      setYears: 'ParagonClientDashboard/setYears',

    }),
  },
};
</script>
