var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav',{staticClass:"m-0",attrs:{"pills":""}},[_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-information' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-information-general' } : { name: 'client-information' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Information ")]),_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-task-notes' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-task-notes-general' } : { name: 'client-task-notes' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Task / Notes ")]),_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-payments' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-payments-general' } : { name: 'client-payments' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Payments ")]),_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-o-files' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-o-files-general' } : { name: 'client-o-files' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Files ")]),_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-access-credentials' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-access-credentials-general' } : { name: 'client-access-credentials' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Access Credentials ")]),_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'bussiness-client-scores' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-scores-general' } : { name: 'client-scores' })),"exact":"","exact-active-class":_vm.isScoresActive,"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Scores ")]),(_vm.validationArrRoles)?_c('b-nav-item',{attrs:{"to":_vm.moduleId === 3
          ? { name: 'client-bussiness-tickets' }
          : ((_vm.$route.matched[0].meta.general ? { name: 'client-tickets-general' } : { name: 'client-tickets' })),"exact":"","exact-active-class":"active border-radius-tabs","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Customer Ticket "),(_vm.G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99 ? '99+' : _vm.G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open,"badge-classes":"badge-important"}})],1):_vm._e()]):_vm._e()],1),_c('b-card',{staticClass:"border-top-primary"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }