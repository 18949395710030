<template>
  <b-modal v-model="isActive" @hidden="closeMe" size="lg" hide-footer>
      <account-client-header :client="client" :account="account" />
      <b-table :items="history" :fields="fields" show-empty>

      </b-table>
  </b-modal>
</template>
<script>
import AccountClientHeader from '@/views/commons/components/applications/views/components/others/AccountClientHeader.vue'
import { mapGetters } from 'vuex'
export default {
  created(){
    this.isActive = this.active
  },
  props:{
    active:{type:Boolean}
  },
  components:{
    AccountClientHeader
  },
  data(){return{
    isActive: false,
    fields:[
      {key:'user_name',label:'Send By'},
      {key:'number',label:'Number'},
      {key:'content',label:'Content'},
    ],
  }},
  methods:{
      closeMe(){
        this.isActive = false
        this.$emit('close')
      },
  },
  computed:{
    ...mapGetters({
        account: 'ParagonClientDashboard/getAccount',
        client: 'ParagonClientDashboard/getClient',
        history: 'ParagonClientDashboard/getHistorySms',
    })
  },
}
</script>