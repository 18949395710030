<template>
  <b-modal v-model="isActive" @hidden="closeMe" size="lg" hide-footer>
      <account-client-header :client="client" :account="account" />
      <b-table :items="history" :fields="fields" show-empty>
        <template #cell(user_name)="data">
          {{ data.item.user_name }} {{ data.item.created_at | myGlobalDay }}
        </template>
        <template #cell(content)="data">
          <div v-html="data.item.content"></div>
        </template>
        <template #cell(files)="data">
          <div v-if="data.item.files != null">
            <li v-for="(file, index) in JSON.parse(data.item.files)" :key="index">
              <a
                  :href="`${data.item.route_t}/FilesEmails/${file}`"
                  target="_blank"
              >{{ file }}</a
              >
            </li>
          </div>
        </template>
      </b-table>
  </b-modal>
</template>
<script>
import AccountClientHeader from '@/views/commons/components/applications/views/components/others/AccountClientHeader.vue'
import { mapGetters } from 'vuex'
export default {
  created(){
    this.isActive = this.active
  },
  props:{
    active:{type:Boolean}
  },
  components:{
    AccountClientHeader
  },
  data(){return{
    isActive:false,
    fields:[
      {key:'user_name',label:'Send By'},
      {key:'content',label:'Content'},
      { key: "files", label: "Files" },
    ],
  }},
  methods:{
      closeMe(){
        this.isActive = false
        this.$emit('close')
      },
  },
  computed:{
    ...mapGetters({
        account: 'ParagonClientDashboard/getAccount',
        client: 'ParagonClientDashboard/getClient',
        history: 'ParagonClientDashboard/getHistoryEmail',
    })
  },
}
</script>