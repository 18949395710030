<template>
  <div>
    <b-nav
      class="m-0"
      pills
    >
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-information' }
            : (($route.matched[0].meta.general ? { name: 'client-information-general' } : { name: 'client-information' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Information
      </b-nav-item>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-task-notes' }
            : (($route.matched[0].meta.general ? { name: 'client-task-notes-general' } : { name: 'client-task-notes' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Task / Notes
      </b-nav-item>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-payments' }
            : (($route.matched[0].meta.general ? { name: 'client-payments-general' } : { name: 'client-payments' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Payments
      </b-nav-item>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-o-files' }
            : (($route.matched[0].meta.general ? { name: 'client-o-files-general' } : { name: 'client-o-files' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Files
      </b-nav-item>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-access-credentials' }
            : (($route.matched[0].meta.general ? { name: 'client-access-credentials-general' } : { name: 'client-access-credentials' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Access Credentials
      </b-nav-item>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-scores' }
            : (($route.matched[0].meta.general ? { name: 'client-scores-general' } : { name: 'client-scores' }))
        "
        exact
        :exact-active-class="isScoresActive"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Scores
      </b-nav-item>
      <b-nav-item
        v-if="validationArrRoles"
        :to="
          moduleId === 3
            ? { name: 'client-bussiness-tickets' }
            : (($route.matched[0].meta.general ? { name: 'client-tickets-general' } : { name: 'client-tickets' }))
        "
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Customer Ticket
        <span
          v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
          class="ml-1"
        >
          <feather-icon
            icon
            :badge="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99 ? '99+' : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open"
            badge-classes="badge-important"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary">
      <router-view />
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    validationArrRoles() {
      if (this.currentUser.arrRoles.find(rol => (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6 || rol.role_id === 17) && rol.module_id !== 24 && rol.user_id !== 38)) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD: 'NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD',
    }),
    isInformationActive() {
      return this.$route.name === 'client-information'
        || this.$route.name === 'bussiness-client-information'
        ? 'active'
        : 'inactive';
    },
    isTaskNotesActive() {
      return this.$route.name === 'client-task-notes'
        || this.$route.name === 'bussiness-client-task-notes'
        ? 'active'
        : 'inactive';
    },
    isPaymentsActive() {
      return this.$route.name === 'client-payments'
        || this.$route.name === 'bussiness-client-payments'
        ? 'active'
        : 'inactive';
    },
    isFilesActive() {
      return this.$route.name === 'client-o-files'
        || this.$route.name === 'bussiness-client-o-files'
        ? 'active'
        : 'inactive';
    },
    isAccessCredentialsActive() {
      return this.$route.name === 'client-access-credentials'
        || this.$route.name === 'bussiness-client-access-credentials'
        ? 'active'
        : 'inactive';
    },
    isScoresActive() {
      return this.$route.name === 'client-scores'
        || this.$route.name === 'bussiness-client-scores'
        ? 'active'
        : 'inactive';
    },
    isTicketsActive() {
      return this.$route.name === 'client-scores'
        || this.$route.name === 'bussiness-client-scores'
        ? 'active'
        : 'inactive';
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({ user_id: this.currentUser.user_id, client_account_id: this.$route.params.idClient });
  },
  methods: {
    ...mapActions({
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD: 'NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD',
    }),
  },
};
</script>
