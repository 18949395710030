<template>
  <div>
    <!-- Share -->
    <b-button
      class="light-blue"
      @click="openShareModal"
      :disabled="userAuthorized || isResponsibleAccount"
    >
      <feather-icon icon="Share2Icon" />
      Share
    </b-button>
    <!-- Other Programs -->
    <b-button
      class="light-blue ml-1"
      :disabled="!hasOthersPrograms"
      @click="openModalProgramList"
    >
      <feather-icon icon="AirplayIcon" class="" />
      Others Programs
    </b-button>
    <!-- Send sms -->
    <b-button :disabled="isResponsibleAccount" class="ml-1" variant="success" @click="openModalSendSms">
      <feather-icon icon="MessageSquareIcon" />Send SMS
    </b-button>
    <b-button class="ml-1" variant="success" @click="openModalHistorySms">
      <tabler-icon icon="ListCheckIcon" />
    </b-button>
    <!-- Send email -->
    <b-button :disabled="isResponsibleAccount" class="ml-1" variant="success" @click="openModalSendEmail">
      <feather-icon icon="AtSignIcon" />Send email
    </b-button>
    <b-button class="ml-1" variant="success" @click="openModalHistoryEmail">
      <tabler-icon icon="ListCheckIcon" />
    </b-button>
    <b-button :disabled="isResponsibleAccount" class="ml-1" @click="openModalHistoryClaims">
      <tabler-icon icon="GridIcon" />Claims
    </b-button>

  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: { hasOthersPrograms: { type: Boolean }, leadId: { type: Number } },

  methods: {

    openShareModal() {
      this.$emit("openShareModal");
    },
    openModalProgramList() {
      this.$emit("openModalProgramList");
    },
    openModalSendSms() {
      this.$emit("openModalSendSms");
    },
    openModalSendEmail() {
      this.$emit("openModalSendEmail");
    },
    openModalHistorySms() {
      this.$emit("openModalHistorySms");
    },
    openModalHistoryEmail() {
      this.$emit("openModalHistoryEmail");
    },
    openModalHistoryClaims() {
      this.$emit("openModalHistoryClaims");
    },
  },
  computed: {
    userAuthorized() {
      return !(
        this.currentUser.role_id === 1 || this.currentUser.role_id === 2 || this.currentUser.role_id === 17
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>
<style scoped>
.content-header .content-header-right .light-blue {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}
</style>